.chat {
  width: 100%;
  height: calc(100vh - 104px);
  position: relative;
  background: white;
}

.search {
  padding: 5px 30px 20px 30px;
  background: white;
  border-bottom: 1px solid #f2f2f2;
}

.sidebar {
  position: relative;
  height: 90%;
  max-height: calc(100% - 180px);
  background: white;
}

.toogleDeal {
  backdrop-filter: blur(20px);
}

.toggleDeal button {
  padding: 43px 15px;
  border-radius: 0px;
  font-size: 16px;
  text-transform: none;
  line-height: none;

  border: none;
  border-bottom: 1px solid #f2f2f2;
}


@keyframes elevateButton {
  0% {
    transform: translateY(0);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 5, 0.2);
  }
  100% {
    transform: translateY(-5px);
    box-shadow: 0px 10px 15px 3px rgba(0, 0, 5, 0.2);
  }
}

.toggleDeal button {
  font-weight: normal;
  border: none;
  background-color: #f2f2f2;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}


.toggleDeal button.Mui-selected {
  animation: elevateButton 0.3s forwards;
  background-color: #e6f7ff;
  font-weight: bold;
  box-shadow: 0px 10px 15px 3px rgba(0, 0, 5, 0.2);
  transform: translateY(-10px);
}

.toggleDeal button:not(.Mui-selected) {
  transform: translateY(0);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 5, 0.2);
}

@media (max-width: 768px) {
  .box-chat{
    margin-top: 40px !important;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 10, 0.2) !important;
  }
  @media (max-width: 576px) {
  }
}
