:root {
  --main-color-primary: #1c4b75;
  --main-color-cargon: #1c4b75;
}

.container {
  max-width: 1245px;
}

.row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 7.5px;
  padding-left: 7.5px;
}

.header-main .right-component {
  text-align: right;
}

.nav-header .dropdown-menu {
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.nav-header .dropdown-menu .dropdown-item {
  padding: 1rem;
  font-weight: 400;
}

.nav-header .dropdown-menu .dropdown-item:hover {
  background: var(--main-color-primary);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  transition: ease-in-out 0.3s;
  border-radius: 5px;
  color: #fff;
}

.nav-header.profile .dropdown-menu {
  margin-left: -108px !important;
}

.nav-header.profile .dropdown-menu .dropdown-item.active,
.dropdown-item:active {
  background: none;
  color: #212529;
}

.nav-profile a {
  color: #fff;
}

.tabs-template {
  border-color: #f2f2f2;
  border-bottom: 0;
}

.tabs-template.nav-tabs .nav-item.show .nav-link,
.tabs-template.nav-tabs .nav-link.active {
  color: #ffffff;
  background: var(--main-color-primary);
  border-color: var(--main-color-primary);
  font-weight: 500;
}

.tabs-template .nav-link {
  padding: 0.5rem 2rem;
}

.btn-align-forms {
  margin-top: 31px;
}

.btn-align-forms-xs {
  margin-top: 35px;
}

.load-label-align {
  margin-top: -6px;
}

.load-list-align {
  padding-left: 25px;
}

.load-adresses {
  border: 1px solid #f2f2f2;
  border-radius: 0px 5px 5px 5px;
}

.travel-adresses.list-maps {
  border-radius: 5px;
  margin-top: 40px;
}

.travel-label-align {
  margin-top: -6px;
}

.travel-list-align {
  padding-left: 25px;
}

.travel-adresses.list-maps {
  border-radius: 5px;
  margin-top: 40px;
}

span.name-icon img {
  width: 12px;
  height: 12px;
}

.filter {
  margin-bottom: 20px;
}

.filter .vertical {
  display: flex;
  flex-direction: row;
}

.filter .vertical.bottom {
  align-items: flex-end;
}

.filter label {
  color: #fff;
}

.modal-title {
  font-size: 20px;
  color: #212529;
}

@media (max-width: 768px) {
  .header-main {
    padding-bottom: 150px;
  }

  .header-main .left-component .text-title {
    font-size: 14px !important;
  }

  .header-main .left-component nav {
    display: none;
  }

  .header-main .right-component button {
    padding-left: 15px;
    padding-right: 15px;
  }

  .nav-header {
    margin-top: 1rem;
  }

  .nav-header .dropdown-toggle {
    padding: 1rem 1rem;
  }

  .nav-header .dropdown-menu {
    background: transparent;
    box-shadow: none;
  }

  .nav-header.profile .dropdown-menu {
    margin-left: 0px !important;
  }

  .nav-header .dropdown-menu .dropdown-item,
  .nav-header.profile .dropdown-menu .dropdown-item {
    padding: 1rem 1.5rem;
    color: #fff;
  }

  .nav-header.profile .dropdown-menu {
    margin-left: 0px;
  }

  .load-adresses.list-maps {
    margin-top: 15px;
  }

  .filter {
    margin-top: 150px;
    margin-bottom: 30px;
  }

  .filter label {
    color: #464e5f;
  }
}

.form .col-12,
.form .col-md-6,
.form .col-md-8,
.form .col {
  margin-bottom: 20px;
}

.pac-container {
  z-index: 9999;
}

.text-information-veh {
  color: white;
}

.text-information-veh:hover {
  cursor: pointer;
}

.icon-light-veh {
  font-size: 150%;
}