.infinite-scroll-component {
  overflow: visible !important;
}

.css-yk16xz-control {
  border-color: #e5eaee !important;
}

.modal-content {
  border-color: transparent !important;
}

.modal-backdrop {
  background-color: rgb(33 37 41 / 50%) !important;
  z-index: 899 !important;
}

.modal-xl {
  width: 80vw !important;
  max-width: 80vw !important;
  margin-left: 180px;
}

.modal-dialog {
  max-width: 80vw !important;
}

.modal-lg {
  max-width: 50vw !important;
}

.full-height-card {
  height: calc(100% - 1rem);
}

.approval-gr {
  display: flex;
  justify-content: flex-end;
}

.backdrop-occurrences-aditional-field {
  z-index: 850 !important;
}
